import DynamicModuleLoader from "./global/DynamicModuleLoader"
import Config from "./global/Config"

class Main {
	constructor() {
		this.introductionData = {}
		this.fetchData()
		this.dynamicLoad()
	}

	fetchData() {
		fetch(Config.dataPath)
			.then(response => response.json())
			.then(data => this.introductionData = data.introduction)
			// eslint-disable-next-line no-console
			.catch(error => console.log(error));
	}

	dynamicLoad() {
		const doCompExist = (classname) => !!document.querySelectorAll(classname).length

		DynamicModuleLoader.load(
			doCompExist(".bb-animation-handler-wrapper"),
			DynamicModuleLoader.modules.AnimationHandlerWrapper,
			(Component) => {
				document.querySelectorAll(".bb-animation-handler-wrapper").forEach((item) => {
					new Component(item)
				})
			},
		)
		DynamicModuleLoader.load(
			doCompExist(".bb-audio-visualiser-wrapper"),
			DynamicModuleLoader.modules.AudioVisualiserWrapper,
			(Component) => {
				document.querySelectorAll(".bb-audio-visualiser-wrapper").forEach((item) => {
					new Component(item)
				})
			},
		)
		DynamicModuleLoader.load(
			doCompExist(".bb-volume-meter-wrapper"),
			DynamicModuleLoader.modules.VolumeMeterWrapper,
			(Component) => {
				document.querySelectorAll(".bb-volume-meter-wrapper").forEach((item) => {
					new Component(item)
				})
			},
		)
		DynamicModuleLoader.load(
			doCompExist(".bb-communication-with-server"),
			DynamicModuleLoader.modules.CommunicationWithServer,
			(Component) => {
				document.querySelectorAll(".bb-communication-with-server").forEach((item) => {
					new Component(item)
				})
			},
		)
		DynamicModuleLoader.load(
			doCompExist(".bb-scene"),
			DynamicModuleLoader.modules.Scene,
			(Component) => {
				document.querySelectorAll(".bb-scene").forEach((item) => {
					new Component(item)
				})
			},
		)

		DynamicModuleLoader.load(
			doCompExist(".login"),
			DynamicModuleLoader.modules.Login,
			(Component) => {
				document.querySelectorAll(".login").forEach((item) => {
					new Component(item)
				})
			},
		)

		DynamicModuleLoader.load(
			doCompExist(".introduction"),
			DynamicModuleLoader.modules.Introduction,
			(Component) => {
				document.querySelectorAll(".introduction").forEach((item) => {
					new Component(item, this.introductionData)
				})
			},
		)

		DynamicModuleLoader.load(
			doCompExist(".checklist"),
			DynamicModuleLoader.modules.Checklist,
			(Component) => {
				document.querySelectorAll(".checklist").forEach((item) => {
					new Component(item)
				})
			},
		)
	}
}

window.addEventListener(
	"load",
	() => {
		new Main()
	},
	false,
)
