const Config = {
	debug: false,
	isDev: process.isDev,
	version: process.version,
	gameStage: {
		10: {
			id: 1,
			name: "gift",
		},
		20: {
			id: 2,
			name: "animal",
		},
		30: {
			id: 3,
			name: "barrel",
		},
		40: {
			id: 4,
			name: "snowPile",
		},
		50: {
			id: 5,
			name: "santa",
		},
	},
	api: {
		status: {
			live: './api/public/status',
			dev: 'https://xmas.dev.bippesbrandao.net/2024/api/public/status'
		},
		login: {
			live: './api/public/login',
			dev: 'https://xmas.dev.bippesbrandao.net/2024/api/public/login'
		},
		save: {
			live: './api/public/user/save',
			dev: 'https://xmas.dev.bippesbrandao.net/2024/api/public/user/save',
		},
		donation: {
			live: './api/public/donation',
			dev: 'https://xmas.dev.bippesbrandao.net/2024/api/public/donation'
		}
	},
	getApi: (endpoint) => {
		return Config.api[endpoint][Config.isDev ? "dev" : "live"]
	}
}

export default { ...window.bbConfig, ...Config }
