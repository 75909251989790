const modules = {
	AnimationHandlerWrapper: "AnimationHandlerWrapper",
	AudioVisualiserWrapper: "AudioVisualiserWrapper",
	CommunicationWithServer: "CommunicationWithServer",
	VolumeMeterWrapper: "VolumeMeterWrapper",
	Scene: "Scene",
	Login: "Login",
	Introduction: "Introduction",
	Checklist: "Checklist",
}

const loadedModules = {}

class DynamicModuleLoader {
	/**
	 * get the list of dynamic Modules
	 * @returns object
	 */
	get modules() {
		return modules
	}

	/**
	 * use webpack dynamic imports
	 * @param componentName {string}
	 * @param callback {function}
	 * @private
	 */
	_import(componentName, callback) {
		if (typeof componentName === "string" && typeof callback === "function") {
			let modulePromise

			switch (componentName) {
				case modules.AnimationHandlerWrapper:
					modulePromise = import(
						/* webpackChunkName: "AnimationHandlerWrapper" */ "../components/AnimationHandlerWrapper"
					)
					break
				case modules.AudioVisualiserWrapper:
					modulePromise = import(
						/* webpackChunkName: "AudioVisualiserWrapper" */ "../components/AudioVisualiserWrapper"
					)
					break
				case modules.VolumeMeterWrapper:
					modulePromise = import(
						/* webpackChunkName: "VolumeMeterWrapper" */ "../components/VolumeMeterWrapper"
					)
					break
				case modules.Scene:
					modulePromise = import(
						/* webpackChunkName: "Scene" */ "../components/Scene"
					)
					break
				case modules.Login:
					modulePromise = import(
						/* webpackChunkName: "Login" */ "../components/Login"
						)
					break
				case modules.Introduction:
					modulePromise = import(/* webpackChunkName: "Introduction" */ "../components/Introduction")
					break
				case modules.Checklist:
					modulePromise = import(/* webpackChunkName: "Checklist" */ "../components/Checklist")
					break
				case modules.CommunicationWithServer:
					modulePromise = import(
						/* webpackChunkName: "CommunicationWithServer" */ "../components/CommunicationWithServer"
					)
					break
				default:
					// eslint-disable-next-line no-console
					console.warn("Module not found")
					break
			}

			modulePromise.then((component) => {
				callback(component.default)
			})
		}
	}

	/**
	 * Dynamically Module Loader
	 * @param condition {boolean}
	 * @param componentName {string}
	 * @param onLoadComponent {function}
	 */
	load(condition, componentName, onLoadComponent = undefined) {
		if (condition) {
			if (!loadedModules[componentName]) {
				this._import(componentName, (Component) => {
					if (typeof onLoadComponent === "function") {
						onLoadComponent(Component)
					} else {
						new Component()
					}
					loadedModules[componentName] = Component
				})
			} else {
				if (typeof onLoadComponent === "function") {
					onLoadComponent(loadedModules[componentName])
				}
			}
		}
	}
}

export default new DynamicModuleLoader()
